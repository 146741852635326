import { AxiosError } from 'axios';
import { redirectToLoginPath } from './redirectToLoginPath';

export const responseInterceptor = (error: AxiosError): { ignoreExceptions: boolean } => {
  if (error.response?.status === 401) {
    redirectToLoginPath();
    return { ignoreExceptions: true };
  }
  return { ignoreExceptions: false };
};
