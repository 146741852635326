import { retriable } from '~/apis/timeeApi/retriable';
import { aspidaClient } from '~/categories/admin/apis/utils/aspidaClient';
import { AdminUserRole } from '~/categories/admin/models/types/adminUser';

export interface IUserApi {
  fetchUserRole: () => Promise<AdminUserRole[]>;
}

export const userApi: IUserApi = {
  fetchUserRole: () => {
    return retriable(async () => await aspidaClient.api.v1.admin_users.me.roles.$get());
  },
};
