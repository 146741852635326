import { aspidaClient } from '~/categories/admin/apis/utils/aspidaClient';
import {
  ProposalDetail,
  ProposalListType,
  ProposalProgressRequest,
  ProposalRequest,
  ProposalSummary,
} from '~/categories/admin/models/types/proposal';

export interface IProposalApi {
  fetchProposals(params: { type: ProposalListType }): Promise<ProposalSummary[]>;
  fetchProposal(params: { proposalId: number }): Promise<ProposalDetail>;
  requestProposal(params: ProposalRequest): Promise<void>;
  approveProposalProgress(params: ProposalProgressRequest): Promise<void>;
  denyProposalProgress(params: ProposalProgressRequest): Promise<void>;
}

// TODO: 以下のようにretriableを付与する
// fetchAccount: () => {
//   return retriable(async () => await await aspidaClient.api.v1.account.$get());
// },

export const proposalApi: IProposalApi = {
  fetchProposals: async (params) => {
    return (await aspidaClient.api.v1.proposals.$get({ query: { type: params.type } })).results;
  },
  fetchProposal: (params) => {
    return aspidaClient.api.v1.proposals._proposal_id(params.proposalId).$get();
  },
  requestProposal: (params) => {
    return aspidaClient.api.v1.proposals
      ._proposal_id(params.proposalId)
      .request.$patch({ body: { reason: params.reason, evidences: params.evidences } });
  },
  approveProposalProgress: (params) => {
    return (
      aspidaClient.api.v1.proposal_progresses
        ._proposal_progress_id(params.proposalProgressId)
        // TODO: reasonがundefinedableだったので確認する
        .$patch({ body: { status: 'approved', reason: params.reason } })
    );
  },
  denyProposalProgress: (params) => {
    return aspidaClient.api.v1.proposal_progresses
      ._proposal_progress_id(params.proposalProgressId)
      .$patch({ body: { status: 'denied', reason: params.reason } });
  },
};
