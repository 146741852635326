import { aspidaClient } from '~/categories/admin/apis/utils/aspidaClient';
import { adminPathBuilder } from '~/categories/admin/constants/adminPathBuilder';
import { PageReplaceNextLink } from '~/models/types/common';

export interface IAuthApi {
  logout: () => Promise<PageReplaceNextLink>;
}

export const authApi: IAuthApi = {
  logout: async () => {
    await aspidaClient.api.v1.sign_out.$delete();
    return { pageReplaceNextLink: adminPathBuilder().login() };
  },
};
