import { stringify } from 'querystring';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { apiConfig } from '~/constants/apiConfig';
import { envManager } from '~/constants/envManager';
import { responseInterceptor } from './responseInterceptor';

export const configuredAxiosClient = (): AxiosInstance => {
  const client = axios.create({
    baseURL: envManager.publicEnv.apiBaseUrl,
    withCredentials: true, // https://qiita.com/m_mitsuhide/items/7b638cf93dd086f27b8f
    paramsSerializer: (params) => stringify(params),
    headers: { [apiConfig.timeeAdminApiCustomHeader]: apiConfig.timeeAdminApiCustomHeader },
  });

  // NOTE: interceptors.request.useとinterceptors.response.useを一緒に書くとどちらかが機能しなくなるので注意

  client.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const { ignoreExceptions } = responseInterceptor(error);
      if (ignoreExceptions) return;
      return Promise.reject(error);
    },
  );

  return client;
};
