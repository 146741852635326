/* eslint-disable */
import { AspidaClient, BasicHeaders, dataToURLString } from 'aspida'
import { Methods as Methods0 } from './api/v1/admin_users/me/roles'
import { Methods as Methods1 } from './api/v1/business_fact_checks'
import { Methods as Methods2 } from './api/v1/business_fact_checks/_business_fact_check_id@number'
import { Methods as Methods3 } from './api/v1/graphql'
import { Methods as Methods4 } from './api/v1/master/business_fact_check/evidence/reject_reason_types'
import { Methods as Methods5 } from './api/v1/master/business_fact_check/evidence/types'
import { Methods as Methods6 } from './api/v1/proposal_progresses/_proposal_progress_id@number'
import { Methods as Methods7 } from './api/v1/proposals'
import { Methods as Methods8 } from './api/v1/proposals/_proposal_id@number'
import { Methods as Methods9 } from './api/v1/proposals/_proposal_id@number/request'
import { Methods as Methods10 } from './api/v1/sign_in/consume'
import { Methods as Methods11 } from './api/v1/sign_out'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/admin/api/v1/admin_users/me/roles'
  const PATH1 = '/admin/api/v1/business_fact_checks'
  const PATH2 = '/admin/api/v1/graphql'
  const PATH3 = '/admin/api/v1/master/business_fact_check/evidence/reject_reason_types'
  const PATH4 = '/admin/api/v1/master/business_fact_check/evidence/types'
  const PATH5 = '/admin/api/v1/proposal_progresses'
  const PATH6 = '/admin/api/v1/proposals'
  const PATH7 = '/request'
  const PATH8 = '/admin/api/v1/sign_in/consume'
  const PATH9 = '/admin/api/v1/sign_out'
  const GET = 'GET'
  const POST = 'POST'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    api: {
      v1: {
        admin_users: {
          me: {
            roles: {
              get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
                fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
              $get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
                fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH0}`
            }
          }
        },
        business_fact_checks: {
          _business_fact_check_id: (val0: number) => {
            const prefix0 = `${PATH1}/${val0}`

            return {
              get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, prefix0, GET, option).json(),
              $get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, prefix0, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods2['patch']['reqBody'], headers?: Methods2['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods2['patch']['status']>(prefix, prefix0, PATCH, option).send(),
              $patch: (option: { body: Methods2['patch']['reqBody'], headers?: Methods2['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods2['patch']['status']>(prefix, prefix0, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix0}`
            }
          },
          get: (option: { query: Methods1['get']['query'], headers?: Methods1['get']['reqHeaders'], config?: T }) =>
            fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, PATH1, GET, option).json(),
          $get: (option: { query: Methods1['get']['query'], headers?: Methods1['get']['reqHeaders'], config?: T }) =>
            fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, PATH1, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods1['get']['query'] }) =>
            `${prefix}${PATH1}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        graphql: {
          post: (option: { body: Methods3['post']['reqBody'], headers?: Methods3['post']['reqHeaders'], config?: T }) =>
            fetch<Methods3['post']['resBody'], BasicHeaders, Methods3['post']['status']>(prefix, PATH2, POST, option).json(),
          $post: (option: { body: Methods3['post']['reqBody'], headers?: Methods3['post']['reqHeaders'], config?: T }) =>
            fetch<Methods3['post']['resBody'], BasicHeaders, Methods3['post']['status']>(prefix, PATH2, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH2}`
        },
        master: {
          business_fact_check: {
            evidence: {
              reject_reason_types: {
                get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).json(),
                $get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH3}`
              },
              types: {
                get: (option?: { headers?: Methods5['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH4, GET, option).json(),
                $get: (option?: { headers?: Methods5['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH4}`
              }
            }
          }
        },
        proposal_progresses: {
          _proposal_progress_id: (val1: number) => {
            const prefix1 = `${PATH5}/${val1}`

            return {
              patch: (option: { body: Methods6['patch']['reqBody'], headers?: Methods6['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['patch']['status']>(prefix, prefix1, PATCH, option).send(),
              $patch: (option: { body: Methods6['patch']['reqBody'], headers?: Methods6['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['patch']['status']>(prefix, prefix1, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix1}`
            }
          }
        },
        proposals: {
          _proposal_id: (val2: number) => {
            const prefix2 = `${PATH6}/${val2}`

            return {
              request: {
                patch: (option: { body: Methods9['patch']['reqBody'], headers?: Methods9['patch']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods9['patch']['status']>(prefix, `${prefix2}${PATH7}`, PATCH, option).send(),
                $patch: (option: { body: Methods9['patch']['reqBody'], headers?: Methods9['patch']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods9['patch']['status']>(prefix, `${prefix2}${PATH7}`, PATCH, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH7}`
              },
              get: (option?: { headers?: Methods8['get']['reqHeaders'], config?: T }) =>
                fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, prefix2, GET, option).json(),
              $get: (option?: { headers?: Methods8['get']['reqHeaders'], config?: T }) =>
                fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}`
            }
          },
          get: (option: { query: Methods7['get']['query'], headers?: Methods7['get']['reqHeaders'], config?: T }) =>
            fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).json(),
          $get: (option: { query: Methods7['get']['query'], headers?: Methods7['get']['reqHeaders'], config?: T }) =>
            fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods7['get']['query'] }) =>
            `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        sign_in: {
          consume: {
            post: (option: { body: Methods10['post']['reqBody'], config?: T }) =>
              fetch(prefix, PATH8, POST, option).send(),
            $post: (option: { body: Methods10['post']['reqBody'], config?: T }) =>
              fetch(prefix, PATH8, POST, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH8}`
          },
        },
        sign_out: {
          delete: (option?: { headers?: Methods11['delete']['reqHeaders'], config?: T }) =>
            fetch<void, Methods11['delete']['resHeaders'], Methods11['delete']['status']>(prefix, PATH9, DELETE, option).send(),
          $delete: (option?: { headers?: Methods11['delete']['reqHeaders'], config?: T }) =>
            fetch<void, Methods11['delete']['resHeaders'], Methods11['delete']['status']>(prefix, PATH9, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH9}`
        }
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
