import { AxiosError } from 'axios';
import axios from 'axios';
import { apiConfig } from '~/constants/apiConfig';
import { envManager } from '~/constants/envManager';
import { ApiErrorResponse } from '~/models/types/common';
import { wait } from '~/utils/wait';

const redirectOnError = (error: AxiosError<ApiErrorResponse>) => {
  if (typeof error.response?.data?.redirectUrl === 'string') {
    location.replace(error.response.data.redirectUrl);
  }
};

class RefreshRetryHandler {
  private refreshing = false;

  // 通常のクライアントとinterceptorをごっちゃにしたくないので別で生成
  private refreshClient = axios.create({
    baseURL: envManager.publicEnv.apiBaseUrl,
    withCredentials: true,
    headers: {
      [apiConfig.timeeApiCustomHeader]: apiConfig.timeeApiCustomHeader,
    },
  });

  constructor() {}

  private waitForReloaded = async () => {
    const loop = (callback: (value?: unknown) => void) => {
      if (!this.refreshing) {
        callback();
        return;
      }

      setTimeout(() => {
        loop(callback);
      }, 100);
    };

    return new Promise((resolve) => {
      loop(resolve);
    });
  };

  private refreshToken = async () => {
    await this.refreshClient.request({
      url: '/app/api/v1/sign_in/refresh',
      method: 'patch',
    });
  };

  retriable = async <T>(apiCall: () => Promise<T>): Promise<T> => {
    try {
      // 通常時
      return await apiCall();
    } catch (error) {
      // エラー時

      // Unauthorized(401)の時のみリトライ
      if ((error as AxiosError).response?.status !== 401) {
        throw error;
      }

      // リフレッシュする
      if (this.refreshing) {
        // 他でリフレッシュされていたらソレを待つ
        await this.waitForReloaded();
      } else {
        // リフレッシュされていなかったら実行
        this.refreshing = true;
        try {
          await this.refreshToken();
        } catch (refreshError) {
          redirectOnError(refreshError as AxiosError<ApiErrorResponse>);
        } finally {
          this.refreshing = false;
        }
      }

      // 2回目
      await wait(apiConfig.retryWaitMillSec);
      return apiCall();
    }
  };
}

export const retriable = new RefreshRetryHandler().retriable;
