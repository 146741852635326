import { NextRouter, useRouter } from 'next/router';
import { adminApiClient, IAdminApiClient } from '~/categories/admin/apis/adminApiClient';
import { useToastMessages, Option } from '~/hooks/context/useToastMessages';
import { MessageHandler } from '~/types/message';

export type AdminPageContext = {
  router: NextRouter;
  apiClient: IAdminApiClient;
  showMessage: MessageHandler<Option>;
};

export const useAdminPageContext = (): AdminPageContext => {
  const router = useRouter();
  const client = adminApiClient;
  const toastHooks = useToastMessages();

  return {
    router,
    apiClient: client,
    showMessage: toastHooks.actions.pushMessage,
  };
};
