import { authApi, IAuthApi } from '~/categories/admin/apis/adminApiClient/authApi';
import {
  businessFactCheckApi,
  IBusinessFactCheckApi,
} from '~/categories/admin/apis/adminApiClient/businessFactCheckApi';
import { graphqlAPI, IGraphqlAPI } from '~/categories/admin/apis/adminApiClient/graphqlAPI';
import { IProposalApi, proposalApi } from '~/categories/admin/apis/adminApiClient/proposalApi';
import { userApi, IUserApi } from '~/categories/admin/apis/adminApiClient/userApi';

export interface IAdminApiClient
  extends IUserApi,
    IProposalApi,
    IAuthApi,
    IGraphqlAPI,
    IBusinessFactCheckApi {}

export const adminApiClient: IAdminApiClient = {
  ...userApi,
  ...proposalApi,
  ...authApi,
  ...graphqlAPI,
  ...businessFactCheckApi,
};
